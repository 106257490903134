#timer {
    display: flex;
    align-items: center;
    font-weight: bold;
    &.forfeit {
        color: red;
        .button {
            cursor: default;
        }
    }
    &.won {
        color: green;
        .button {
            cursor: default;
        }
    }
}
svg {
    transition: .5s;
    &.button {
        cursor: pointer;
        &:hover {
            color: red;
        }
    
    }
   
}