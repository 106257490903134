#search, #playlist {
    max-height: 40%;
    svg {
        margin-left: 8px;
        flex-shrink: 0;
        transition: .1s;
        &.button {
            cursor: pointer;
            &:hover {
                color: red;
            }
            &:disabled {
                color: blue;
            }
        
        }
       
    }

   
    

    #heading {
        margin: 7px 20px;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;            

        #playlist_title {
            // flex-grow: 1;
            margin: 0px 8px;
            width: 100%;
            border-radius: 0px;
            background: transparent;
            outline:none;
            box-shadow: none;
            // border-bottom: 1px solid white;
            transition: .2s;
            color: white;
            font-size: 25px;                
            outline-offset: 0px !important;

            &:empty {
                display: block;
            }
            &:focus {
                border-bottom: 1px solid red;
                outline-offset: 0px !important;
                // text-decoration: underline;
                // text-decoration-color: red;
            }
        }
        
        #toolbar {
            display: flex;
            align-items: center;
            border-radius: 5px;
            font-size: 18px;
            padding: 2px 10px;
            padding-right: 18px;
            background: white;
            color: black;
            #fileinput {
                display: none;
            }
        }
    }
    #alt {
        font-size: 15px;
        margin: 10px;
    }
    text-align: center;
    #search_bar {
        margin: 10px 20px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        input, button {
            flex-grow: 1;
            font-size: 15px;
            min-height: 20px !important;
            padding: 2px;
            margin: 5px 0px;
            border: 0px;
        }
        input {
            padding: 2px 8px;
        }
        button {
            margin-left: 10px;
        }
    }
    #search_results {
        &.empty {
            max-height: 0px !important;
            padding: 0px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: red;
        }
        background-color: white;
        color: black;
        margin: 5px 20px;
        padding: 5px;
        border-radius: 5px;
        max-height: 30vh;
        overflow-y: scroll;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        transition: .5s;
        .result {
            flex-shrink: 0;
            padding: 3px;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 2px;
            background: darken(white, 10%);
            font-size: 15px;
            text-align: left;
            flex-wrap: nowrap;
            .info{
                display: flex;
                img {
                    align-self: center;
                    height: 40px !important;
                    width: 40px !important;
                    margin-left: 15px;
                }
                .text {
                    display: flex;
                    flex-direction: column;
                    margin: 0px 8px;
                }
            }
            
            * {
                &.song {
                    color: red;
                }
                &.artist {
                    color: black;
                    text-decoration: underline;
                    cursor:pointer;
                }
            }
            svg {
                margin-right: 30px;
                flex-shrink: 0;
                font-size: 25px;
                transition: .1s;
                &.button {
                    cursor: pointer;
                    &:hover {
                        color: red;
                    }
                
                }
               
            }
        }
    }
    #playlist_length {
        font-size: 14px;
    }
    
}