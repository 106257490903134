@import url('https://fonts.googleapis.com/css?family=Pacifico&display=swap');

/* width */
::-webkit-scrollbar {
    z-index: 1!important;
    width: 10px;
    transition: .5s ease-in-out;
    scroll-behavior: smooth !important;
        border-radius: 15px!important;

  }
  
/* Track */
::-webkit-scrollbar-track {
    border-radius: 15px!important;
    background: rgb(231, 231, 231);
    border-left: 2px solid transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    transition: background-color .5s;
    background-color: black;
    cursor:pointer;
    border-radius: 15px!important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    // transition: background-color .5s;
    // background-color: transparent;
    // border: 2px solid black;
    cursor:pointer;
}

input {
    height: 28px!important;
    padding: 3px;
    outline: none;
    border-radius: 5px;  
    border: 2px solid black; 
    box-shadow: 0px 0px red; 
    height: 20px;
    transition: .2s;
    &:focus {
        border: 1.5px solid black;
        box-shadow: 2px 2px red;
    }
    &:disabled {
        background: darken(white, 15%)
    }    
}
button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 5px;
    background-color: black;
    color: white;
    border: 2px solid black; 
    cursor: pointer;
    transition: .2s;
    outline: none;
    text-transform: uppercase;
    font-weight: bold;
    &:hover {
        // color: black;
        background-color: red;
        border: 2px solid red; 
    }
    &:disabled {
        background-color: lighten(black, 20%);
        border: 2px solid lighten(black, 20%); 
        cursor: default;
    }
}

#game {
    height: 100vh;
    max-width: 100%;
    display: flex;
    overflow: hidden;
    #apptitle {
        font-family: 'Pacifico', cursive !important;
        font-size: 28px;
        cursor: pointer;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                            supported by Chrome, Opera and Firefox */
    }
}