@mixin noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Opera and Firefox */
}


#home {
    overflow: hidden;
    margin: auto;
    #head {
        max-width: 90%;
        margin: auto;
         .big {
            @include noselect;
            font-family: 'Pacifico', cursive !important;
            font-size: 45px;
            // margin-bottom: 5px;
            text-align: center !important;

        }
        text-align: left;

        padding-bottom: 1vh;
        border-bottom: 1px solid black;
        margin-bottom: 1vh;
    }
   
    max-width: 700px;
    margin: 2vh auto;

    #nav {
        max-width: 90%;
        margin: auto;
        padding-bottom: 1vh;
        border-bottom: 1px solid black;
        margin-bottom: 1vh;
       
        svg {
            color: black;
            cursor: pointer;
            font-size: 25px;
            padding: 4px 0px;
            margin: 0px 10px;
            transition: .2s;
            &:hover {
                color: red;
            }
            &.active {
                border-bottom: 1px solid black;
            }
        }
    }
    
    .home_body {
        max-width: 90%;
        margin: auto;
        .title {
            @include noselect;
            font-family: 'Pacifico', cursive !important;
            font-size: 150%;
            font-weight: bold;
            margin-bottom: 10px;
        }
        .faq, .about, .settings {
            max-height: 50vh;
            overflow: auto;
        }
        .settings { 
            .MuiSwitch-switchBase.Mui-checked {
                color: red !important;   
            }
            .MuiTypography-body1 {
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                sans-serif !important;
            }
        }
        ul {
            text-align: left;
            p {
                padding: 5px 20px;
            }
        }
    }
}