#outer_display {
   margin: auto;
   max-width: 800px;

   #winner {
       font-size: 30px;
       margin: 10px;
   }
    #display {
        max-width: 75vw;
        height: 90vh;
        margin:auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin:20px;
        #info {
            display: flex;
            justify-content: center;
            margin: 5px;
            * {
                margin: 0px 2vw;
            }
            min-height: 20px;
        }
        #song_title {
            background: lighten(black, 40%);
            margin: 5px 0px;
            text-transform: uppercase;
            font-weight: bold;
            
            &.forfeit {
                background: rgb(253, 54, 54);
            }
            &.won {
                background: rgb(6, 240, 6);
            }
        }
        #lyrics {
            position: relative;
            #pause_overlay {
                position: absolute;
                padding-top: 30px;
                top:0;
                right:0;
                left:0;
                bottom:0;
                // display: flex;
                // justify-content: center;
                // align-items: center;
            
                background:white;
            }
            overflow-y: auto;
            padding-right: 1vw;
            // margin: 10px 50px;
            // border: 2px black solid;
            display: flex;
            justify-content: left;
            flex-wrap: wrap;
            .word {
                margin: 3px 2px;
                padding: 0px 3px;
                font-size: 2vh;
                background-color: #cacaca;
                border-radius: 3px;
                // border: 0.1px black solid;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                transition: .02s;
                &.forfeit {
                    color: red;
                }
                &.undiscovered {
                    color: transparent;
                }
            }
        }
    }
    
}
