#sidebar {
    * {
        transition: .5s;
    }
    #header {
        background-color: lighten(black, 30%);
        transition: .5s;
        // padding: 1px;
        display: flex;
        flex-wrap: nowrap;
        .spacer {
            flex-grow: 1;
        }
        #title {
            margin-left: 12px;
            margin-top: 0px;
        }
        svg {
            // transition: .5s;
            flex-shrink: 0;
            // margin-top: 6px;
            margin-right: 8px;
            align-self: center;
            cursor: pointer;
            vertical-align: text-bottom;
            &.icon-hidden {
                transform: translate(-86px);
            }
        }
    }
    #children {
        display: flex;
        flex-direction: column;
        width: 400px;
        max-width: 100vw;
        // &.hidden {
        //     transform: translate(-min(400px, 100vw))
    
        // }
    }
    
    &.hidden {
        width: 44px;

        // transform: translate(-360px)

    }
    .hidden {
        transform: translate(-400px)

    }
    input, button {
        min-height: 30px !important;
        padding: 3px;
        margin: 10px 3px;
    }
    margin:0px;
    background-color: black;
    color: white;
    font-size: 24px;
    text-align: right;
    width: 400px;
    max-width: 100vw;
    height: 100%;
    transition: .5s;
    
    
    
    // &.expanded {

    //     width: 400px;
        
    // }
}